<template>
<div id="page" class="text-report-manage">
  <el-row>
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="keyword">
        <el-input v-model="form.keyword" clearable placeholder="用户姓名/联系方式">
          <template #prepend>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item prop="deviceTypeCode" label="设备类型">
        <el-select v-model="form.deviceTypeCode">
          <el-option v-for="(item, index) in options" :key="index" :label="item.deviceTypeName" :value="item.deviceTypeCode" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txtUsername" label="用户姓名" align="center">
      <template #default="scope">{{ scope.row.txtUsername||"- -" }}</template>
    </el-table-column>
    <!-- <el-table-column prop="intGender" label="性别" align="center">
      <template #default="scope">
        <template v-if="scope.row.intGender==0">女</template>
        <template v-else>男</template>
      </template>
    </el-table-column>
    <el-table-column prop="intAge" label="年龄" align="center" /> -->
    <el-table-column prop="txtMobile" label="联系方式" align="center">
      <template #default="scope">{{ scope.row.txtMobile||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="txtTpName" label="检测项目" align="center" />
    <el-table-column prop="dtPayment" label="检测时间" align="center">
      <template #default="scope">{{ scope.row.dtPayment||"- -" }}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="handleDetail(scope.row)">查看详情</div>
        <div class="table-icon" @click="printPreview(scope.row)">打印预览</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <detail v-if="dialog.detailShow" :detailData="dialog.detail" @close="dialog.detailShow=false" />
  <print v-if="dialog.printShow" :printData="dialog.print" @close="dialog.printShow=false" />
</div>
</template>

<script>
// 检测报告管理
import moment from "moment";
import { useStore } from "vuex";
import Print from "./comp/Print.vue";
import Detail from "./comp/Detail.vue";
import { Pagination } from "components";
import { ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { getClinicOrders, getDeviceTypeList } from "api/apis.js";
import { computed, onMounted, reactive, toRefs, ref } from "vue";
export default {
  components: {
    Search,
    Pagination,
    Detail,
    Print
  },
  setup() {
    const store = useStore();
    const pageRef = ref(null);
    const state = reactive({
      form: {
        keyword: null,
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        page: 1,
        limit: 10,
        deviceTypeCode: ""
      },
      options: [],
      table: {
        data: [],
        loading: false,
        total: 0
      },
      dialog: {
        detailShow: false,
        detail: {},
        print: {},
        printShow: false
      },
      userInfo: computed(() => store.getters.userInfo)
    });

    onMounted(() => {
      getDevices();
    })

    // 获取设备类型
    const getDevices = () => {
      getDeviceTypeList({ loginId: state.userInfo.clinicConfigId }).then(response => {
        if (response.code == 200) {
          state.options = response.data;
          // 设备类型 默认显示第一个 
          state.form.deviceTypeCode = state.options[0].deviceTypeCode;
          // 获取订单列表
          getList();
        } else {
          ElMessage.error(response.message);
        }
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.loading = true;
      let param = {
        query: {
          loginId: state.userInfo.clinicConfigId,
          // loginId: 17,
          txtSearch: state.form.keyword,
          dtFrom: (state.form.dateRange || []).length > 0 ? state.form.dateRange[0] : null,
          dtTo: (state.form.dateRange || []).length > 0 ? state.form.dateRange[1] : null,
          deviceTypeCode: state.form.deviceTypeCode
        },
        pageNum: state.form.page,
        pageSize: state.form.limit,
      }
      getClinicOrders(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.data = response.data.list;
            state.table.total = response.data.total;
          } else {
            state.table.data = [];
            state.table.total = 0;
          }
        } else {
          state.table.data = [];
          state.table.total = 0;
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 查看详情
    const handleDetail = row => {
      state.dialog.detail = row;
      state.dialog.detail.deviceTypeCode = state.form.deviceTypeCode;
      state.dialog.detailShow = true;
    };

    // 打印预览
    const printPreview = row => {
      state.dialog.print = row;
      state.dialog.print.deviceTypeCode = state.form.deviceTypeCode;
      state.dialog.printShow = true;
    };

    // 表单重置
    const reset = () => {
      state.form = {
        keyword: null,
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        // 设备类型 默认显示第一个 
        deviceTypeCode: state.options[0].deviceTypeCode,
        page: 1,
        limit: 10
      }
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleDetail,
      printPreview,
      getDevices,
      pageRef
    }
  }
}
</script>

<style lang="scss" scoped>
.text-report-manage {
  /deep/ .el-form {
    .el-input {
      .el-input-group__prepend {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
      }

      .el-input__wrapper {
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
      }
    }

    .el-select {
      .el-input__wrapper {
        border-radius: 14px;
      }
    }

    .el-date-editor {
      border-radius: 14px;
    }
  }

  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
